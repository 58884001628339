<template>
    <v-container grid-list-xl style="max-width: 1540px">
        <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        ></v-progress-linear>
        <h1 v-if="!loading">Energy Adjustment: <b>{{analyticModelName}} - {{nreName}}</b></h1>
          <bigchart :energyAdjustmentData="energyAdjustments" v-if="showChart"></bigchart>
    </v-container>
</template>

<script>
import api from "../_api";
import moment from "moment";
import BigChart from "../../../../../components/Charts/EnergyAdjustmentLargeDataChart.vue";
export default {
  components: {
    bigchart: BigChart
  },

  data() {
    return {
        loading: true,
        showChart: false,
        nreId: null,
        version: null,
        energyAdjustments: null,
        nreName: null
    };
  },

  async created() {
    this.nreId = this.$route.params.nonRoutineEventId;
    this.version = this.$route.params.version

    await api.getChartData(this.nreId, this.version).then((result) => {    
      this.nreName = result.data.nonRoutineName;
      this.energyAdjustments = this.mapChartSeriesData(result.data.energyAdjustmentValues);
      this.loading = false;
      this.showChart = true;
    });
  },

  methods: {
    mapChartSeriesData(energyAdjustmentValues) {
      const makeSeriesDataPoint = (item, property) => {
        var unixDate = moment(item.date).unix()
        var value = item[property];
        return [unixDate, value];
      };
      var seriesData = [
        {
          name: "Energy Adjustment",
          data: energyAdjustmentValues.map((item) => makeSeriesDataPoint(item, "value")),
        }
      ];
      return seriesData;
    }
  }

};
</script>